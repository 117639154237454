import HttpClient from "core/http/httpClient";

const ContactUsService = () => {
  let httpClient = HttpClient();

  const sendMessage = (payload) => {
    let uri = "/contact";
    return httpClient.post(uri, {
      ...payload,
    });
  };

  return {
    sendMessage,
  };
};

export default ContactUsService;
