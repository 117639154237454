import ActionTypes from "./action-types";

const initialState = {
  isLoading: false,
  isSuccessful: false,
};

export default function ContactUsReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.CONTACT_US_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.CONTACT_US_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccessful: true,
      };
    case ActionTypes.CONTACT_US_FAILURE:
      return {
        ...state,
        isLoading: false,
        isSuccessful: false,
      };
    case ActionTypes.CONTACT_US_CLEAN_UP:
      return initialState;
    default:
      return state;
  }
}
