import Error403 from "./../views/pages/403/error-403";
import Error404 from "./../views/pages/404/error-404";
import Error500 from "./../views/pages/500/error-500";
import Maintenance from "./../views/pages/maintenance/maintenance";

var miscPagesRoutes = [
  { path: "/pages/maintenance", component: Maintenance },
  { path: "/pages/error-403", component: Error403 },
  { path: "/pages/error-404", component: Error404 },
  { path: "/pages/error-500", component: Error500 }
];

export default miscPagesRoutes;
