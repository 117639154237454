import React from "react";

class Error403 extends React.Component {
  render() {
    return (
      <div className="error-box">
        <div
          className="error-body text-center"
          style={{ maxWidth: 800, margin: "0 auto" }}
        >
          <div className="mt-4">
            <h3 style={{ lineHeight: "1.5em" }}>
              This is awkward! It looks like you&rsquo;ve tried to access
              something you shouldn&rsquo;t.
            </h3>
            <h5 className="text-muted font-medium mt-4">
              If you believe you should have access to this page, please contact
              your system administrator.
            </h5>
          </div>
        </div>
      </div>
    );
  }
}

export default Error403;
