import React from "react";

class Maintenance extends React.Component {
  render() {
    return (
      <div className="error-box">
        <div
          className="error-body text-center"
          style={{ maxWidth: 800, margin: "0 auto" }}
        >
          <div className="mt-4">
            <h3 style={{ lineHeight: "1.5em" }}>
              Oh snap! One of two things has happened, either your internet
              connection has dropped out, or we might be doing some maintenance.
            </h3>
          </div>
        </div>
      </div>
    );
  }
}

export default Maintenance;
