import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Main from "./main";
import * as ActionCreators from "./redux/action-creators";
import { withFormik } from "formik";
import contactUsInitialValues from "./formik/formik/initial-values";
import contactUsValidationSchema from "./formik/formik/validation-schema";

const mapStateToProps = state => {
  return {
    contactUs: state.contactUs,
    profile: state.system.userProfile
  };
};

const EnhancedForm = withFormik({
  mapPropsToValues: () => contactUsInitialValues,
  validationSchema: contactUsValidationSchema,
  handleSubmit: (values, { props }) => {
    props.sendMessage(values);
  },
  displayName: "contactUsForm"
})(Main);

export default withRouter(
  connect(mapStateToProps, { ...ActionCreators })(EnhancedForm)
);
